import React from "react";
import PropTypes from "prop-types";
import Modal from "theme/components/organisms/Modal/Modal";
import FormActions from "theme/components/molecules/Form/FormActions";
import Button from "theme/components/atoms/Button";
import FormTitle from "theme/components/molecules/Form/FormTitle";
import { FormattedMessage } from "react-intl";

const ConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  children,
  confirmActionLabel,
  confirmActionAppearance = "primary",
  cancelActionLabel,
  loading,
}) => {
  return (
    <Modal
      dangerouslyIgnoreCloseButton
      isOpen={isOpen}
      onRequestClose={() => onCancel()}
      contentLabel={title}
      className="confirmation-modal"
    >
      <FormTitle>{children}</FormTitle>

      <FormActions appearance="center">
        {cancelActionLabel ? (
          <Button
            type="button"
            state={loading ? "disabled" : "default"}
            onClick={() => onCancel()}
          >
            {cancelActionLabel}
          </Button>
        ) : (
          <Button
            type="button"
            state={loading ? "disabled" : "default"}
            onClick={() => onCancel()}
          >
            <FormattedMessage
              id="components.organisms.Modal.ConfirmationModal.cancel"
              defaultMessage="Cancel"
            />
          </Button>
        )}

        <Button
          type="button"
          state={loading ? "pending" : null}
          onClick={(event) => onConfirm(event)}
          appearance={confirmActionAppearance}
        >
          {confirmActionLabel}
        </Button>
      </FormActions>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  confirmActionLabel: PropTypes.node.isRequired,
  confirmActionAppearance: PropTypes.string,
  cancelActionLabel: PropTypes.node,
  loading: PropTypes.bool,
};

export default ConfirmationModal;
