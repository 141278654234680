import React from "react";
import classNames from "classnames";
import { useDisplayPrice } from "theme/components/molecules/PriceDisplaySwitch/useDisplayPrice";
import formatPrice from "theme/utils/formatPrice";

const wrapperComponents = {
  span: ({ children, className }) => {
    return <span className={className}>{children}</span>;
  },
  div: ({ children, className }) => {
    return <span className={className}>{children}</span>;
  },
};

const Price = ({ hiddable, label, price, priceFallback }) => {
  const { isPriceHidden } = useDisplayPrice();
  const Wrapper = label ? wrapperComponents.div : wrapperComponents.span;

  return (
    <Wrapper
      className={classNames("price", {
        "price--hidden": isPriceHidden && hiddable,
      })}
    >
      {label ? (
        <>
          {label}&nbsp;{":"}&nbsp;
        </>
      ) : (
        ""
      )}

      <div>{formatPrice(price, priceFallback)}</div>
    </Wrapper>
  );
};

export default Price;
