import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ReactModal from "react-modal";
import CloseModal from "./CloseModal";
import useDelayedIsOpen from "theme/components/organisms/Modal/useDelayedIsOpen";
import { modalOpenedBodyClassName } from "./useBodyFixed";

const Modal = (props) => {
  const [delayedIsOpen, onRequestClose] = useDelayedIsOpen(
    props.isOpen,
    props.onRequestClose,
    300
  );
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={onRequestClose}
      className={classnames("modal", {
        [`modal--${props.appearance}`]: props.appearance,
        [`modal--opened`]: delayedIsOpen === true,
        [`modal--closing`]: delayedIsOpen === false,
        [props.className]: props.className,
      })}
      overlayClassName={classnames("modal__overlay", {
        [`modal__overlay--${props.appearance}`]: props.appearance,
        [`modal__overlay--opened`]: delayedIsOpen === true,
        [`modal__overlay--closing`]: delayedIsOpen === false,
      })}
      bodyOpenClassName={modalOpenedBodyClassName}
    >
      {props.naked ? (
        props.children
      ) : (
        <div>
          {!props.dangerouslyIgnoreCloseButton && (
            <CloseModal onClick={onRequestClose} />
          )}
          <div className="modal__content">
            {props.title ? (
              <div className="modal__title">{props.title}</div>
            ) : null}
            {props.children}
            {props.actions}
          </div>
        </div>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  children: PropTypes.node,
  actions: PropTypes.node,
  dangerouslyIgnoreCloseButton: PropTypes.bool,
  appearance: PropTypes.oneOf(["default", "full", "full-no-spacing"]),
  className: PropTypes.string,
  naked: PropTypes.bool,
};

if (typeof window !== "undefined") {
  ReactModal.setAppElement("body");
}

export default Modal;
